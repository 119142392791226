<template>
  <div>
    <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
      {{registerTitle}}
    </h3>
    <div
      class="wet-input wet-input-with-label"
      :class="$v.email.$error ? 'wet-input-error' : ''"
    >
      <input
        id="wet-input-email"
        type="email"
        :placeholder="emailPlaceHolder"
        v-model.trim="$v.email.$model"
      >
      <label for="wet-input-email">
        {{ emailPlaceHolder }}
      </label>
    </div>
    <div
      v-if="$v.email.$error && $v.email.taken"
      class="small text-primary mt-n2 mb-3 mx-3"
    >
      {{ emailError }}
    </div>
    <div v-if="!$v.email.taken" class="small text-primary mt-n2 mb-3 mx-3">
      {{ emailAlreadyUsedError }}
    </div>
    <div
      class="wet-input wet-input-with-label has-addons"
      :class="$v.password.$error ? 'wet-input-error' : ''"
    >
      <input
        id="wet-input-password"
        :type="showPass ? 'text' : 'password'"
        :placeholder="passwordPlaceHolder"
        v-model="$v.password.$model"
      >
      <label for="wet-input-password">
        {{ passwordPlaceHolder }}
      </label>
      <button
        class="btn lh-1"
        @click="showPass = !showPass"
      >
        <WetIcon class="wet-icon text-gray" :type="showPass ? 'eye' : 'eye-strike'" />
      </button>
    </div>
    <div v-if="$v.password.$error" class="small text-primary mt-n2 mb-3 mx-3">
      {{ errorPassword }}
    </div>
    <div
      class="wet-input has-addons wet-input-with-label"
      :class="$v.passwordRetype.$error ? 'wet-input-error' : ''"
    >
      <input
        id="wet-input-password-retype"
        :type="showPassRetype ? 'text' : 'password'"
        :placeholder="passwordRetypePlaceHolder"
        v-model="$v.passwordRetype.$model"
      >
      <label for="wet-input-password-retype">
        {{ passwordRetypePlaceHolder }}
      </label>
      <button
        class="btn lh-1"
        @click="showPassRetype = !showPassRetype"
      >
        <WetIcon class="wet-icon text-gray" :type="showPassRetype ? 'eye' : 'eye-strike'" />
      </button>
    </div>
    <div v-if="$v.passwordRetype.$error" class="small text-primary mt-n2 mb-3 mx-3">
      {{ passwordRetypeError }}
    </div>
  </div>
</template>

<script>
import {
  password,
  passwordRetype,
} from '@/common/validations';
import { email, required } from 'vuelidate/lib/validators';

export default {
  components: {
    WetIcon: () => import('@/components/wetIcon.vue'),
  },
  props: {
    registerTitle: {
      type: String,
      required: true,
    },
    emailPlaceHolder: {
      type: String,
      required: true,
    },
    emailError: {
      type: String,
      required: true,
    },
    emailAlreadyUsedError: {
      type: String,
      required: true,
    },
    passwordPlaceHolder: {
      type: String,
      required: true,
    },
    errorPassword: {
      type: String,
      required: true,
    },
    passwordRetypePlaceHolder: {
      type: String,
      required: true,
    },
    passwordRetypeError: {
      type: String,
      required: true,
    },
    emailTaken: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    email(newEmail) {
      this.$emit('change-email', newEmail);
    },
    password(newPassword) {
      this.$emit('change-password', newPassword);
    },
  },
  data() {
    return {
      showPass: false,
      showPassRetype: false,
      email: '',
      password: '',
      passwordRetype: '',
    };
  },
  validations() {
    return {
      password,
      passwordRetype,
      email: {
        required,
        email,
        taken: () => !this.emailTaken,
      },
    };
  },
};
</script>
